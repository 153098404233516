import React, {useState, useEffect} from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

// import Grid from '@mui/material/Grid';
// import Container from '@mui/material/Container';
import {Link} from 'react-router-dom';
// import { Typography } from '@mui/material';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import TaskAltIcon from '@mui/icons-material/TaskAlt';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import { InputLabel, Select, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import axios from "axios";

// axios.defaults.baseURL='https://web.cheapwebguru.com/angitia/api/';

function Quotation() {

    const errtxt={
            fontSize: 'small',
            color: 'red'
        };
    
        let [name,setName]=useState("");
        let [mobile,setMobile]=useState("");
        let [email,setEmail]=useState("");
        let [city,setCity]=useState("");
        let [interest,setInterest]=useState("");
        let [details,setDetails]=useState("");
    
        const [selectedValue, setSelectedValue] = useState('');
    
        const handleChange = (event) => {
            setSelectedValue(event.target.value);
        };
    
        const [iserrname,setIserrname]=useState(false);
        const [iserrmobile,setIserrmobile]=useState(false);
        const [iserremail,setIserremail]=useState(false);
        const [iserrinterest,setIserrinterest]=useState(false);
        const [iserrdetails,setIserrdetails]=useState(false);
    
        let [msg,setMsg]=useState("");
        // let [msgcolor,setMsgcolor]=useState("");
        let [statuscode,setStatuscode]=useState(false);
    
        let [isbtn,setIsbtn]=useState(false);
    
        let [color,setColor]=useState("");
        async function handleQuote(e) {
            setMsg("");
            // setMsgcolor("");
            setColor("");
    
            var flag=1;
    
            if (name.length==0 && name.trim()=="") {
                flag=0;
                setIserrname(true);
            } else {
                setIserrname(false);
            }
    
            if ((/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{9})$/.test(mobile) || !isNaN(mobile)) && mobile.trim()!="") {
                setIserrmobile(false);
            } else {
                flag=0;
                setIserrmobile(true);
            }
            
            if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
                flag=0;
                setIserremail(true);
            } else {
                setIserremail(false);
            }
    
            if (interest.length==0) {
                flag=0;
                setIserrinterest(true);
            } else {
                setIserrinterest(false);
            }
    
            if (details=="") {
                flag=0;
                setIserrdetails(true);
                // alert(details)
            } else {
                setIserrdetails(false);
            }
    
            if (flag==1) {
                var fd=new FormData();
                fd.append('name',name);
                fd.append('email',email);
                fd.append('mobile',mobile);
                fd.append('interest',interest);
                fd.append('details',details);
    
                if (sessionStorage.getItem("myRefValue")!=null) {
                    fd.append('ref',sessionStorage.getItem("myRefValue")); 
                } else {
                    fd.append('ref',null); 
                }
                // alert(fd)
                // e.preventDefault();
    
                // var resp=await axios.post("https://www.cheapwebguru.com/cwguru/api/quote", {name, email, mobile, city, interest});
                var resp=await axios.post("https://www.cheapwebguru.com/blog/api/sendquote", fd);
                var data=resp.data;
    
                setMsg(data.msg);
                // setMsgcolor(data.msgcolor);
                // setColor(data.color);
    
                if (data.statuscode==200) {
                    setName("");
                    setEmail("");
                    setMobile("");
                    setCity("");
                    setInterest("");
                    setDetails("");
                } else {
                    setName("");
                    setEmail("");
                    setMobile("");
                    setCity("");
                    setInterest("");
                    setDetails("");
                }
    
            }
        };
        // function handleQuote() {

        // }

        function handleClose() {
            
        }
    useEffect(() => {
        // gettestimonial();
    }, [])



    const testimonial_carousel = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 2
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 2
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };



    return(
      <>

      {/* our testimonials start */}
    {/* <section className="py-5 testimonials_bg testimonials-row">
    <div className="container">
        <div className="row">
            <div className="text-center col-md-12">
                <header className="mb-5 w-50 m-auto">
                    <h4>Testimonials</h4>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor Lorem ipsum dolor sit amet consetetur sadipscing elitr</p>
                </header>
            </div>
        </div>

        <div className="row">
            <div className="col-md-11 offse-md-1 m-auto">
            
            <Carousel responsive={testimonial_carousel} arrows={false} showDots={true} autoPlay={true} autoPlaySpeed={1500}>


            {testimonialarr.map((e)=>

                <div className="item"  key={e.id}>
                <div className="testimonial_box">
                    <h3>
                        <img src= {imgpath+e.image} alt="" className="testimonial_img" />                        
                        {e.name} -<span> {e.designation}</span></h3>
                    <p>{e.content}</p>
                </div>
                </div>


        )}




</Carousel>
            </div>               
        </div>
      </div>
</section> */}

            <Grid className="quotationBg" pt={5} pb={5}>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12} textAlign="center">
                            <Typography variant="h6" p={0} textAlign="center">
                            {/* */}
                                <Grid item xs={12} textAlign="center">
                                {/* sx={{display: 'flex'}} */}
                                {/* <div className="text-center"> */}
                                <Typography variant="h6" p={0}><img src="images/icon.png" /></Typography>
                                <Typography variant="h4" fontWeight="bold" pb={0} className="whiteTxt">Reach Out With Your Questions</Typography>
                                </Grid>
                                {/* </div> */}
                            </Typography>
                            <Grid item xs={12} textAlign="center" sx={{paddingTop: '10px'}}>
                            <Typography variant="h8" fontWeight="normal" pb={0} className="whiteTxt">For any inquiries or assistance, please feel free to contact us. Our team is available and ready to support you.</Typography>
                            {/* <Typography variant="body" color="primary">Expert Team</Typography> */}
                            </Grid>
                        </Grid>

                        <Grid
                        container 
                        justifyContent="center"  // Centers horizontally
                        alignItems="center"  // Centers vertically
                        sx={{paddingTop: '40px'}}
                        >
                        {/* container spacing={1} */}
                            <Grid xs={2}></Grid>
                            <Grid xs={8}>
                            <Grid sx={{flexGrow: 1, textAlign: "center", fontWeight: 700}}>
                                {msg.length>0?
                                <Grid>
                                    <Grid pt={0} pb={0} sx={{color: '#90EE90'}}>
                                    <Button variant="outline" startIcon={<TaskAltIcon style={{fontSize: "2rem"}} />}></Button>
                                    </Grid>
                                    <Grid pt={0} pb={0} sx={{color: '#90EE90'}}>
                                        {msg}
                                    </Grid>
                                </Grid>
                                :''}
                            </Grid>

                            <Grid item xs={12} mt={0}>
                                <TextField  label="Your Full Name *" variant="outlined"  placeholder="Full Name" fullWidth onChange={(ev)=>{
                                    setName(ev.target.value);
                                    if (ev.target.value.length>0 && ev.target.value.trim()!="") {
                                        setIserrname(false);
                                    }
                                }} value={name} 
                                sx={{
                                    pointerEvents: "auto", // Ensure clicks work
                                    backgroundColor: "white",
                                    borderRadius: "5px",
                                    paddingLeft: "0px",
                                    // "& .MuiOutlinedInput-root": {
                                    //   "& fieldset": {
                                    //     borderColor: "gray", // Optional: Change border color
                                    //   },
                                    //   "&:hover fieldset": {
                                    //     borderColor: "black", // Optional: Hover effect
                                    //   },
                                    //   "&.Mui-focused fieldset": {
                                    //     borderColor: "blue", // Optional: Focus effect
                                    //   },
                                    // },
                                  }}
                                  />

                                {iserrname==true?<Typography className="text text-danger" style={errtxt}>name required</Typography>:''}
                            </Grid>
                            
                            <Grid container spacing={1} mt={3}>
                                <Grid item xs={12} sm={6}>
                                    
                                    <TextField 
                                    label="Your Phone Number" 
                                    inputProps={{ maxLength: 15 }} 
                                    variant="outlined" 
                                    placeholder="Phone Number" 
                                    fullWidth
                                    onChange={(ev)=>{
                                        setMobile(ev.target.value);
                                        if ((/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{9})$/.test(ev.target.value)) || !isNaN(ev.target.value)) {
                                            setIserrmobile(false);
                                        } else {
                                            setIserrmobile(true);
                                        }
                                    }} 
                                    value={mobile} 
                                    sx={{
                                        pointerEvents: "auto", // Ensure clicks work
                                        backgroundColor: "white",
                                        borderRadius: "5px",
                                        paddingLeft: "0px",
                                        // "& .MuiOutlinedInput-root": {
                                        //   "& fieldset": {
                                        //     borderColor: "gray", // Optional: Change border color
                                        //   },
                                        //   "&:hover fieldset": {
                                        //     borderColor: "black", // Optional: Hover effect
                                        //   },
                                        //   "&.Mui-focused fieldset": {
                                        //     borderColor: "blue", // Optional: Focus effect
                                        //   },
                                        // },
                                      }}
                                      />
                                     

                                    {iserrmobile?<Typography className="text text-danger" style={errtxt}>valid phone number required</Typography>:''}
                                </Grid>
                                
                                
                                <Grid item xs={12} sm={6}>
                                    <TextField label="Your Email ID *" variant="outlined"  placeholder="Email ID" fullWidth onChange={(ev)=>{
                                        setEmail(ev.target.value);
                                        if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) || email.length==0) {
                                            setIserremail(false);
                                        }
                                    }} value={email} 
                                    sx={{
                                        pointerEvents: "auto", // Ensure clicks work
                                        backgroundColor: "white",
                                        borderRadius: "5px",
                                        paddingLeft: "0px",
                                        // "& .MuiOutlinedInput-root": {
                                        //   "& fieldset": {
                                        //     borderColor: "gray", // Optional: Change border color
                                        //   },
                                        //   "&:hover fieldset": {
                                        //     borderColor: "black", // Optional: Hover effect
                                        //   },
                                        //   "&.Mui-focused fieldset": {
                                        //     borderColor: "blue", // Optional: Focus effect
                                        //   },
                                        // },
                                      }}
                                      />

                                    {iserremail?<Typography className="text text-danger" style={errtxt}>valid email id required</Typography>:''}
                                </Grid>
                            </Grid>

                            <Grid item xs={12} mt={4}>
                            <InputLabel id="" 
                                sx={{
                                color: "white"
                              }}
                              >Request for Services*</InputLabel>
                            <Select
                                labelId="select-label"
                                id="select"
                                value={interest}
                                onChange={(event)=>{
                                    setInterest(event.target.value);
                                    if (event.target.value.length>0) {
                                        setIserrinterest(false);
                                    }
                                }}
                                label="Select an option"
                                fullWidth
                                sx={{
                                    backgroundColor: "white",
                                    borderRadius: "5px",
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: "gray", // Optional: Change border color
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "black", // Optional: Hover effect
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "blue", // Optional: Focus effect
                                      },
                                    },
                                  }}
                                >
                                {/* <MenuItem value="" className="whiteTxt">
                                    <em>Request for Services</em>
                                </MenuItem> */}
                                <MenuItem value="">Choose your option</MenuItem> 
                                <MenuItem value="Web Design &amp; Development">Web Design &amp; Development</MenuItem>  
                                <MenuItem value="Mobile App Development">Mobile App Development</MenuItem>                          
                                <MenuItem value="Ecommerce Development">Ecommerce Development</MenuItem>
                                <MenuItem value="Digital Ads Marketing">Digital Ads Marketing</MenuItem>  
                            </Select>

                                {iserrinterest?<Typography className="text text-danger" style={errtxt}>select interest</Typography>:''}
                            </Grid>
                            
                            <Grid container spacing={1} mt={3}>
                            <Grid item xs={12} mt={3}>
                                <TextField
                                    label="Your Requirement Details *"
                                    multiline
                                    rows={4}
                                    placeholder="Enter your requirement details"
                                    variant="outlined"
                                    fullWidth
                                    onChange={(ev)=>{
                                    // label="Your Email ID *" variant="outlined"  placeholder="Email ID" fullWidth onChange={(ev)=>{
                                        setDetails(ev.target.value);
                                        if (ev.target.value!="") {
                                            setIserremail(false);
                                        } else {
                                            setIserremail(true);
                                        }
                                    }}
                                    value={details}
                                    sx={{
                                        pointerEvents: "auto", // Ensure clicks work
                                        backgroundColor: "white",
                                        borderRadius: "5px",
                                        paddingLeft: "0px",
                                        // "& .MuiOutlinedInput-root": {
                                        //   "& fieldset": {
                                        //     borderColor: "gray", // Optional: Change border color
                                        //   },
                                        //   "&:hover fieldset": {
                                        //     borderColor: "black", // Optional: Hover effect
                                        //   },
                                        //   "&.Mui-focused fieldset": {
                                        //     borderColor: "blue", // Optional: Focus effect
                                        //   },
                                        // },
                                    }}
                                />
                                {iserrdetails?<Typography className="text text-danger" style={errtxt}>required details</Typography>:''}
                            </Grid>
                            </Grid>
                            
                            <Grid item xs={12} mt={3} container 
                                justifyContent="center"  // Centers horizontally
                                alignItems="center"  // Centers vertically
                            >
                            <Button onClick={handleQuote} sx={{ backgroundColor: "#ff5722", color: "#fff", "&:hover": { backgroundColor: "#e64a19" } }} autoFocus>
                                Request Quote
                            </Button>
                            {/* <Button onClick={handleClose} color="primary">
                                Close
                            </Button> */}
                            </Grid>

                            </Grid>

                            <Grid xs={2}></Grid>
                            {/* <Grid item xs={12} mt={3}>
                            <InputLabel id="select-label">Your Country *</InputLabel>
                            <Select
                                labelId="select-label"
                                id="select"
                                value={interest}
                                onChange={(event)=>{
                                    setInterest(event.target.value);
                                    if (event.target.value.length>0) {
                                        setIserrinterest(false);
                                    }
                                }}
                                label="Select an option"
                                fullWidth
                                >
                                <MenuItem value="">
                                    <em>Select Your Country</em>
                                </MenuItem>
                                <MenuItem value="India">India</MenuItem>                          
                            </Select>

                                {iserrinterest?<Typography className="text text-danger" style={errtxt}>select interest</Typography>:''}
                            </Grid>

                            <Grid item xs={12} mt={2}>
                                <TextField label="Your City *" variant="standard" placeholder="City" fullWidth onChange={(ev)=>{
                                    setCity(ev.target.value);
                                }} value={city} style={{borderBottom: '1px solid #f7faff'}} />
                            </Grid> */}

                            

                            {/* <Grid item xs={12} mt={3}>
                            <InputLabel id="select-label">Select interest *</InputLabel>
                            <Select
                                labelId="select-label"
                                id="select"
                                value={interest}
                                onChange={(event)=>{
                                    setInterest(event.target.value);
                                    if (event.target.value.length>0) {
                                        setIserrinterest(false);
                                    }
                                }}
                                label="Select an option"
                                fullWidth
                                >
                                <MenuItem value="">
                                    <em>Your Interest</em>
                                </MenuItem>
                                <MenuItem value="Shopping/Ecommerce Website">Shopping/Ecommerce Website</MenuItem>
                                <MenuItem value="Tour Travel/Event Website">Tour Travel/Event Website</MenuItem>
                                <MenuItem value="Educational/Academic Website">Educational/Academic Website</MenuItem>
                                <MenuItem value="Wedding Website">Wedding Website</MenuItem>
                                <MenuItem value="Kid-friendly Website">Kid-friendly Website</MenuItem>
                                <MenuItem value="Doctor Appointment Website">Doctor Appointment Website</MenuItem>
                                <MenuItem value="Booking Website">Booking Website</MenuItem>
                                <MenuItem value="Blog/Forum Website">Blog/Forum Website</MenuItem>
                                <MenuItem value="Non Profit/NGO Website">Non Profit/NGO Website</MenuItem>
                                <MenuItem value="Blog/Forum Website">Blog/Forum Website</MenuItem>
                                <MenuItem value="Billing/Stock Project">Billing/Stock Project</MenuItem>
                                <MenuItem value="Informatic Website">Informatic Website</MenuItem>
                                <MenuItem value="Other Website">Other Website</MenuItem>
                                <MenuItem value="Mobile Application">Mobile Application</MenuItem>
                                <MenuItem value="Google Advertising">Google Advertising</MenuItem>
                                <MenuItem value="Website Traffic Booster">Website Traffic Booster</MenuItem>
                                <MenuItem value="Logo/Banner Design">Logo / Banner Design</MenuItem>                            
                            </Select>

                                {iserrinterest?<Typography className="text text-danger" style={errtxt}>select interest</Typography>:''}
                            </Grid> */}

                            

                        </Grid>

                    
                        {/* <Grid item md={3} sm={6} xs={6}>
                            <Card>
                                <img src="images/team/kaushik.jpg" width="100%" />
                           
                                <CardContent>
                                    <Typography variant="h5" textAlign="center" color="primary">
                                    Kaushik Maitra
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary" textAlign="center">Fullstack Developer</Typography>
                                </CardContent>                        
                            </Card>
                        </Grid>

                        <Grid item md={3} sm={6} xs={6}>
                            <Card>
                                <img src="images/team/cw-user.jpg" width="100%" />
                                <CardContent>
                                    <Typography variant="h5" textAlign="center" color="primary">
                                    Ashok Mahato
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary" textAlign="center">Frontend Developer</Typography>
                                </CardContent>                        
                            </Card>
                        </Grid>

                        <Grid item md={3} sm={6} xs={6}>
                            <Card>
                                <img src="images/team/cw-user.jpg" width="100%" />
                                <CardContent>
                                    <Typography variant="h5" textAlign="center" color="primary">
                                    Mrinmoy Koley
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary" textAlign="center">Laravel, Node JS Developer</Typography>
                                </CardContent>                        
                            </Card>
                        </Grid>

                        <Grid item md={3} sm={6} xs={6}>
                            <Card>
                                <img src="images/team/cw-user.jpg" width="100%" />
                                <CardContent>
                                    <Typography variant="h5" textAlign="center" color="primary">
                                    Manish Mandal
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary" textAlign="center">Website/App Designer</Typography>
                                </CardContent>                        
                            </Card>
                        </Grid> */}

                      

                      
                        
                </Grid>
                </Container>
            </Grid>
      
      
      

      
{/* our testimonials End */}





      </>
  );
}

export default Quotation;